<template>
  <section id="user-logged-out">
    <auth-forms-wrapper height="482px" width="448px">
      <template #default>
        <div id="user-logged-out__content" class="">
          <v-row no-gutters>
            <v-col class="d-flex justify-center">
              <v-img
                id="user-logged-out-image"
                alt="Logged out successfully"
                :aspect-ratio="13 / 9"
                :src="$images.userLoggedOut"
              />
            </v-col>
          </v-row>

          <div class="pt-2 pb-6" id="user-logged-out-title">
            <h3 class="subtitle-1 text--primary font-weight-large text-center">
              {{ $dictionary.app.loggedOut.title }}
            </h3>
          </div>
          <div class="form-group">
            <v-btn
              link
              block
              color="primary"
              :to="$paths.login"
              class="button font-weight-bold"
            >
              LOGIN AGAIN
            </v-btn>
          </div>
        </div>
      </template>
    </auth-forms-wrapper>
  </section>
</template>

<script>
import { AuthFormsWrapper } from "@/components/shared";
import { defer } from "@/utils/common.utils";
/**
 * Logged out view to be displayed when user logs out
 */
export default {
  name: "LoggedOut",
  /**
   * ---------- Data Properties ------------
   */
  data: () => ({
    timerRef: null,
  }),
  /**
   * ---------- Components ------------
   */
  components: {
    AuthFormsWrapper,
  },
  /**
   * ---------- Mounted lifecycle hook ------------
   */
  mounted() {
    const TIMEOUT_MILLIS = 10000;

    const callback = () => this.$router.replace(this.$paths.login);
    this.timerRef = defer(callback, TIMEOUT_MILLIS);
  },
  /**
   * Component destroyed lifecycle hook
   */
  destroyed() {
    clearTimeout(this.timerRef);
  },
};
</script>
